import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import "../../styles/odooServices.scss"
import "../../styles/services.scss"
import News from "../../components/News"
import OdooTopSection from "../../components/OdooTopSection"
import WhyChooseVisiomateforD365 from "../../components/WhyChooseVisiomateforD365"
import Dynamics365OptimizationImageWithTextSection from "../../components/Dynamics365OptimizationImageWithTextSection"
import OdooUpgradeSimplifiedStepstogetyouStarted from "../../components/OdooUpgradeSimplifiedStepstogetyouStarted"
import Wethoughtyoumighthavequestions from "../../components/Wethoughtyoumighthavequestions"

const Index = ({ data }) => {
    return (
        <div className="Upgrade">
            <Layout pageTitle={"Visiomate - Upgrade of Odoo"}
            descrip = {"Upgrade your Odoo ERP to the latest version seamlessly. Unlock new features, improve performance, and future-proof your business. Learn more!"}>
                <OdooTopSection
                    headerData={{
                        title:
                            "We are committed to facilitating seamless business transformations with our comprehensive Odoo upgrade services.",
                    }}
                    data={data}
                />
                <Dynamics365OptimizationImageWithTextSection
                    content={{
                        heading:
                            "Boost Your Business: Unlock Growth Potential with Latest Odoo",
                        description:
                            "Optimize your business by migrating from lower version to higher version and embracing the new performance boosting features! Visiomate's expert services ensure a seamless transition, minimizing disruptions and maximizing the benefits of upgrading to Latest Odoo version. ",
                        d2:
                            "Enhance agility, automate tasks, and succeed in your digital transformation journey with our  lower version to higher version upgrade services.",
                    }}

                    data={data} />
                <WhyChooseVisiomateforD365 data={{
                    title: 'Why Choose Visiomate for Odoo Upgrade?', headings: [
                        { heading: 'Crafting Tailored Solutions', description: "At Visiomate, our experienced professionals acknowledge the uniqueness of each business. We take pride in crafting tailored Odoo solutions that seamlessly align with your organization's goals and processes." },
                        { heading: 'Commitment to Excellence', description: "Choosing Visiomate means choosing a commitment to excellence. The team is dedicated to delivering a superior Odoo upgrade experience, meeting and exceeding your expectations." },
                        { heading: 'Proactive Support at Every Turn', description: "Our commitment extends beyond implementation. We stand by your side with proactive support services, ensuring the continuous seamless operation of your Odoo environment. Our dedicated team provides timely assistance, updates, and a commitment to ongoing improvements." },
                        { heading: 'Navigating Industries with Expertise', description: "Visiomate brings a wealth of industry-specific knowledge to the forefront. Whether you're navigating fashion trends, optimizing inventory management, or addressing supply chain intricacies, our expertise is tailored to elevate and refine your operations." },
                    ]

                }}
                    dataFormImages={data} />
                {/* <RecentSuccessStories data={{
                    title: 'Recent Success Stories ', Text: [
                        { description: "Our commitment goes beyond implementation. We stand steadfast by your side with proactive support services. ", image: img1 },
                        { description: "Our commitment goes beyond implementation. We stand steadfast by your side with proactive support services. ", image: img2 },

                    ]
                }} */}
                    {/* dataFormImages={data} /> */}
                <OdooUpgradeSimplifiedStepstogetyouStarted />
                <Wethoughtyoumighthavequestions
                    data={[
                        {
                            question: "Why should I upgrade my Odoo version?",
                            answer:
                                "Upgrading your Odoo version offers several benefits, including access to new features, improvements in performance and security, compatibility with the latest technologies, bug fixes, and ongoing support from Odoo.",
                            answer1: "",
                            answer2: "",
                            answer3: "",
                            answer4: "",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question:
                                "What are the key considerations before upgrading Odoo?",
                            answer: "Before upgrading Odoo, consider factors such as:",
                            answer1:
                                "- Compatibility: Ensure that any third-party modules or customizations are compatible with the new version of Odoo.",
                            answer2:
                                "- Data Migration: Plan for data migration from the old version to the new version, ensuring data integrity and consistency.",
                            answer3:
                                "- Customizations: Assess the impact of existing customizations and extensions on the upgrade process, and plan for any necessary modifications or updates.",
                            answer4:
                                "- Testing: Perform thorough testing of the upgraded system to identify and address any issues or discrepancies before deploying it in a production environment.",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question: "How do I upgrade my Odoo version?",
                            answer:
                                "The process of upgrading Odoo version typically involves several steps, including:",
                            answer1:
                                "- Backing up your existing Odoo database and files to ensure data safety.",
                            answer2:
                                "- Downloading and installing the latest version of Odoo.",
                            answer3:
                                "- Migrating your data from the old version to the new version using built-in migration tools or scripts.",
                            answer4:
                                "- Testing the upgraded system to ensure functionality, performance, and data integrity.",
                            answer5:
                                "- Deploying the upgraded system in a production environment and providing any necessary user training or support.",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question: "Can I upgrade directly to the latest version of Odoo?",
                            answer:
                                "In most cases, you can upgrade directly to the latest version of Odoo from an older version. However, it's essential to review the release notes, documentation, and compatibility matrix provided by Odoo to ensure a smooth upgrade path.",
                            answer1: "",
                            answer2: "",
                            answer3: "",
                            answer4: "",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question:
                                "What should I do if I encounter issues during the upgrade process?",
                            answer:
                                "If you encounter issues during the upgrade process, such as compatibility problems, data migration errors, or technical issues, you can seek assistance from Odoo support, consulting partners, or the Odoo community. Additionally, you can review the documentation, forums, and knowledge base for troubleshooting tips and solutions.",
                            answer1: "",
                            answer2: "",
                            answer3: "",
                            answer4: "",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question: "How long does it take to upgrade Odoo?",
                            answer:
                                "The duration of the upgrade process depends on various factors, including the size and complexity of your Odoo implementation, the volume of data to be migrated, the extent of customizations, and the availability of resources. In general, upgrading Odoo can take anywhere from a few hours to several days, depending on the specific circumstances.",
                            answer1: "",
                            answer2: "",
                            answer3: "",
                            answer4: "",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question: "What are the risks of upgrading Odoo?",
                            answer:
                                "While upgrading Odoo offers many benefits, there are also some risks to consider, including:",
                            answer1:
                                "- Data Loss: Inadequate backup procedures or data migration errors could lead to data loss or corruption during the upgrade process.",
                            answer2:
                                "- Downtime: Depending on the complexity of the upgrade and any issues encountered, there may be downtime associated with the upgrade, impacting business operations.",
                            answer3:
                                "- Compatibility Issues: Third-party modules, customizations, or integrations may not be compatible with the new version of Odoo, requiring additional development or adjustments.",
                            answer4:
                                "- Disruption: Changes in the user interface, workflows, or functionality may disrupt users' workflows and require additional training or adjustment periods.",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question: "How often should I upgrade my Odoo version?",
                            answer:
                                "It's generally recommended to upgrade your Odoo version regularly to take advantage of new features, improvements, and security updates. Odoo releases new versions approximately every year, so consider upgrading at least once a year to stay current with the latest developments.",
                            answer1: "",
                            answer2: "",
                            answer3: "",
                            answer4: "",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                    ]}
                    title="We thought you might have questions"
                />
                <News isAbout={true} career={true} />
            </Layout>
        </div>
    )
}

export default Index
export const query = graphql`
  query OdooUpgradeImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odooimg: file(relativePath: { eq: "Odoobg.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markFlag: file(
      relativePath: { eq: "4879902_check_flag_mark_ok_icon_1_copy.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    RSSImages1: file(
      relativePath: { eq: "DemoImages/jaredd-craig-HH4WBGNyltc-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    RSSImages2: file(
      relativePath: {
        eq: "DemoImages/kourosh-qaffari-RrhhzitYizg-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SecondDivImg: file(
      relativePath: {
        eq: "upgrade-update-new-version-better-graphics-concept 1.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
